import React, { useState, useEffect } from "react"
import { API, graphqlOperation } from "aws-amplify"
import { getUserNews } from "../graphql/queries"
import Amplify from "aws-amplify"
import awsExports from "../../static/scripts/aws-exports"
import Layout from "../components/layout"
import NewsDetails from "../components/newsdetails"
import { navigate, Link } from "gatsby"
import { useQueryParam } from "gatsby-query-params"
import CatNews from "../components/catnews"
import { ArticleConsumer, PlayerContext } from "../components/player-context"
import { showHideLogin } from "../components/user-menu"
import { isLoggedIn } from "../utils/auth"

const MySources = path => {
  const [isLoading, setIsLoading] = useState(true)
  const [result, setResult] = useState({
    data: null,
  })

  useEffect(() => {
    async function fetchData() {
      try {
        if (isLoggedIn())
          if (!result.data) {
            setIsLoading(true)
            Amplify.configure(awsExports)
            var operation = graphqlOperation(getUserNews)
            operation.authMode = "AMAZON_COGNITO_USER_POOLS"
            setResult(await API.graphql(operation))
            console.log(result)
            setIsLoading(false)
          }
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    }
    registerPageReady()
    fetchData()
  })

  return (
    <>
      {isLoading && isLoggedIn() && (
        <Layout>
          <div className="wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="data-loading">
                    <i className="fal fa-spinner-third"></i>
                    <div>جاري التحميل...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
      {!isLoading && isLoggedIn() && (
        <>
          {result.data &&
            result.data.getUserNews &&
            result.data.getUserNews.articles && (
              <>
                {result.data.getUserNews.articles.length > 0 && (
                  <CatNews
                    items={result.data.getUserNews.articles}
                    catArabicName="مصادري"
                    hidePlayButton={true}
                  ></CatNews>
                )}
                {result.data.getUserNews.articles.length <= 0 && (
                  <Layout>
                    <div className="wrapper">
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div class="no-news-found">
                              <div class="no-news-wrapper">
                                <div>
                                  لا يوجد اخبار جديدة من المصادر التي تتابعها
                                </div>
                                <Link to="/sources">تعديل مصادري</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Layout>
                )}
              </>
            )}
          {(!result.data ||
            !result.data.getUserNews ||
            !result.data.getUserNews.articles) && (
            <>
              <Layout>
                <div className="wrapper">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div class="no-news-found">
                          <div class="no-news-wrapper">
                            <div>الرجاء إختيار مصادر التي ترغب في متابعتها</div>
                            <Link to="/sources">تعديل مصادري</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Layout>
            </>
          )}
        </>
      )}
      {!isLoggedIn() && (
        <Layout>
          <div className="wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div class="no-news-found">
                    <div class="no-news-wrapper">
                      <div>الرجاء تسجيل الدخول لرؤية المصادر التي اخترتها</div>
                      <button onClick={showHideLogin}>تسجيل الدخول</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  )
}

export function registerPageReady() {
  const script = document.createElement("script")
  script.type = "text/javascript"
  script.text = `try{pageReady();}catch{}`
  document.body.appendChild(script)
}

export function doShare(info) {
  if (info) {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.text = `doShare('${info.title}',"/article/${info.id}")`
    document.body.appendChild(script)
  }
}

export default MySources
